import { Form, Formik, FieldArray } from "formik";
import { Button, Divider } from "antd";
import { DatePicker } from "antd";

import * as Yup from "yup";
import TextInput from "./TextInput";

import "./../styles.css";

const { RangePicker } = DatePicker;

const Form2 = ({ appState, setAppState, setCurrent }) => {
  const handlePrev = () => {
    setCurrent((pre) => pre - 1);
  };

  return (
    <Formik
      initialValues={
        appState.education || {
          degree: [
            { university: "", college: "", dateRange: "", dateMoment: "" },
          ],
        }
      }
      validationSchema={Yup.object().shape({
        degree: Yup.array().of(
          Yup.object().shape({
            university: Yup.string().required("university is required"),
            college: Yup.string().required("college is required"),
          })
        ),
      })}
      onSubmit={(values) => {
        setCurrent((val) => val + 1);

        setAppState((pre) => {
          if (pre.education) {
            pre.education = values;
            return pre;
          }

          return { ...pre, education: values };
        });
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <FieldArray name="degree">
            {({ remove, push }) => {
              const { degree } = values;

              return (
                <div>
                  {degree.map((item, index) => {
                    return (
                      <div className="row my-4 " key={index}>
                        <div className="row col-sm">
                          <TextInput
                            name={`degree[${index}].university`}
                            label="University"
                            placeholder="Enter you university"
                          />
                        </div>
                        <div
                          className="row col-sm"
                          style={{ marginBottom: 100 }}
                        >
                          <TextInput
                            name={`degree[${index}].college`}
                            label="College"
                            placeholder="Enter you college"
                          />
                        </div>

                        <div className="row my-4">
                          <div>
                            <label htmlFor="date">Year</label>
                            <div>
                              <RangePicker
                                id="date"
                                size="large"
                                placement="bottomRight"
                                value={values.degree[index].dateMoment}
                                onChange={(dateStrings) => {
                                  const dates = dateStrings.map((item) => {
                                    const year = item.toDate().getFullYear();
                                    const month = item.toDate().getMonth();
                                    return `${month}/${year}`;
                                  });
                                  setFieldValue(
                                    `degree[${index}].dateRange`,
                                    dates
                                  );
                                  setFieldValue(
                                    `degree[${index}].dateMoment`,
                                    dateStrings
                                  );
                                }}
                              />
                              <Divider />
                            </div>
                          </div>
                        </div>
                        {index > 0 && (
                          <div className="col-3 my-4">
                            <Button
                              size="large"
                              danger
                              onClick={() => remove(index)}
                            >
                              remove
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div>
                    {degree.length < 3 && (
                      <div className="col-3">
                        <Button
                          color="#006400"
                          onClick={() =>
                            push({ university: "", college: "", dateRange: "" })
                          }
                        >
                          Add Education
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          </FieldArray>
          <div className="my-5">
            <Button
              size="large"
              className="  "
              type="primary"
              onClick={handlePrev}
            >
              Prev
            </Button>
            <Button
              size="large"
              className="mx-2 "
              type="primary"
              htmlType="submit"
            >
              Next
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Form2;
