import { Field } from "formik";
import { Input } from "antd";

const TextInput = ({ name, placeholder, type = "text", label }) => {
  return (
    <div className="my-2">
      <label htmlFor={name}>{label}</label>
      <Field name={name}>
        {({ field, meta }) => (
        <div>
            <Input
              size="large"
              type='{type}'
              {...field}
              placeholder={placeholder}
              style={{ width: "100%" }}
            />
            {meta.touched && meta.error && (
              <div style={{ color: "red" }}>{meta.error}</div>
            )}
          </div>
        )}
      </Field>
    </div>
  );
};

export default TextInput;
