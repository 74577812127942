import { Form, Formik, Field, FieldArray } from "formik";
import { Button, Divider } from "antd";
import { DatePicker } from "antd";
import * as Yup from "yup";
import TextInput from "./TextInput";
import TextArea from "antd/es/input/TextArea";

const { RangePicker } = DatePicker;

const Form4 = ({ appState, setAppState, setCurrent }) => {
  const handlePrev = () => {
    setCurrent((pre) => pre - 1);
  };

  return (
    <Formik
      initialValues={
        appState.experience || {
          experience: [
            {
              position: "",
              company: "",
              dateRange: "",
              dateMoment: "",
              description: "",
            },
          ],
        }
      }
      validationSchema={Yup.object().shape({
        experience: Yup.array().of(
          Yup.object().shape({
            position: Yup.string().required("position is required"),
            company: Yup.string().required("company is required"),
            descriptopm: Yup.string(),
          })
        ),
      })}
      onSubmit={(values) => {
        setCurrent((val) => val + 1);

        setAppState((pre) => {
          if (pre.experience) {
            pre.experience = values;
            return pre;
          }

          return { ...pre, experience: values };
        });
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <FieldArray name="experience">
            {({ remove, push }) => {
              const { experience } = values;

              return (
                <div>
                  {experience.map((item, index) => {
                    return (
                      <div className="row my-4 " key={index}>
                        <div className="row col-sm">
                          <TextInput
                            name={`experience[${index}].position`}
                            label="Position"
                            placeholder="Enter you position"
                          />
                        </div>
                        <div className="row col-sm">
                          <TextInput
                            name={`experience[${index}].company`}
                            label="Company"
                            placeholder="Enter you company"
                          />
                        </div>
                        <div className="my-4">
                          <Field name={`experience[${index}].description`}>
                            {({ field }) => {
                              return (
                                <div>
                                  <label
                                    htmlFor={`experience[${index}].description`}
                                  >
                                    Description
                                  </label>
                                  <TextArea
                                    type="textarea"
                                    name={`experience[${index}].description`}
                                    placeholder="Enter description"
                                    {...field}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </div>

                        <div className="row my-4">
                          <div>
                            <label htmlFor="date">Year</label>
                            <div>
                              <RangePicker
                                className="col-6"
                                id="date"
                                size="large"
                                value={values.experience[index].dateMoment}
                                onChange={(dateStrings) => {
                                  const dates = dateStrings.map((item) => {
                                    const year = item.toDate().getFullYear();
                                    const month = item.toDate().getMonth();
                                    return `${month}/${year}`;
                                  });
                                  setFieldValue(
                                    `experience[${index}].dateRange`,
                                    dates
                                  );
                                  setFieldValue(
                                    `experience[${index}].dateMoment`,
                                    dateStrings
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {index > 0 && (
                          <div className="col-3 my-4">
                            <Button
                              size="large"
                              danger
                              onClick={() => remove(index)}
                            >
                              remove
                            </Button>
                          </div>
                        )}

                        <Divider />
                      </div>
                    );
                  })}
                  <div>
                    {experience.length < 3 && (
                      <div className="col-3">
                        <Button
                          color="#006400"
                          onClick={() =>
                            push({
                              position: "",
                              company: "",
                              dateRange: "",
                              experience: "",
                            })
                          }
                        >
                          Add experience
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          </FieldArray>
          <div className="my-5">
            <Button
              size="large"
              className="  "
              type="primary"
              onClick={handlePrev}
            >
              Prev
            </Button>
            <Button
              size="large"
              className="mx-2 "
              type="primary"
              htmlType="submit"
            >
              Next
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Form4;
