import { useState } from "react";
import { Button, Steps } from "antd";
import PdfFile from "./components/PdfFile";
import Form1 from "./components/Form1";
import Form2 from "./components/Form2";
import Form3 from "./components/Form3";
import Form4 from "./components/Form4";
// import CvTemplate from "./components/CvTemplate";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "./styles.css";
import Welcome from "./components/Welcome";

const { Step } = Steps;

const App = () => {
  const [current, setCurrent] = useState(0);
  const [appState, setAppState] = useState({});

  if (current === 0) {
    return <Welcome setCurrent={setCurrent} />;
  }

  return (
    <div className="container">
      <div className="d-flex flex-column" style={{ margin: "20px" }}>
        <Steps current={current - 1} style={{ margin: "20px 0" }}>
          <Step title="Personal Info" key="personalInfo" />
          <Step title="Experience" key="experience" />
          <Step title="Education" key="educatiion" />
          <Step title="Skills" key="skills" />
          <Step title="Finish" key="finish" />
        </Steps>

        {current === 1 && (
          <Form1
            appState={appState}
            setAppState={setAppState}
            setCurrent={setCurrent}
          />
        )}

        {current === 2 && (
          <Form4
            appState={appState}
            setAppState={setAppState}
            setCurrent={setCurrent}
          />
        )}

        {current === 3 && (
          <Form2
            appState={appState}
            setAppState={setAppState}
            setCurrent={setCurrent}
          />
        )}

        {current === 4 && (
          <Form3
            appState={appState}
            setAppState={setAppState}
            setCurrent={setCurrent}
          />
        )}

        {current === 5 && (
          <div>
            <div
              style={{ height: "50vh" }}
              className="d-flex  justify-content-center align-items-center "
            >
              <Button
                size="large"
                style={{ marginBottom: "-20px" }}
                className=" my-2 mx-2"
                type="primary"
                onClick={() => {
                  setCurrent((pre) => pre - 1);
                }}
              >
                Edit
              </Button>

              <PDFDownloadLink
                document={<PdfFile appState={appState} />}
                fileName="cv"
              >
                {({ loading }) =>
                  loading ? (
                    <Button className="btn" disabled>
                      Loading Document
                    </Button>
                  ) : (
                    <Button size="large" className="btn btn-success ">
                      Download
                    </Button>
                  )
                }
              </PDFDownloadLink>
            </div>

            {/* <CvTemplate appState={appState} /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
