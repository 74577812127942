import {
  Page,
  Text,
  Document,
  // Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
// import Idon from "./../images/logo192.png";

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#183D3D",
    height: "20%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    gap: 10,
  },
  headerInfo: {
    color: "#93B1A6",
    fontSize: 14,
  },
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,

    color: "#93B1A6",
  },
  textHeader: {
    fontSize: 16,
    color: "#040D12",
    marginBottom: 10,
  },
  textSubHeader: {
    fontSize: 14,
    color: "#040D12",
    marginBottom: 5,
  },
  secText: {
    fontSize: 13,
    color: "#040D12",
    marginBottom: 5,
    opacity: 0.8,
  },
  text: {
    fontSize: 12,
    color: "#040D12",
    marginBottom: 5,
    opacity: 0.7,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: "auto",
    maxWidth: 100,
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  section: {
    marginVertical: 10,
  },
  outer: {
    borderWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
  },
  content: {
    padding: 15,
    // height: "100%",
    // borderWidth: 1,
    // borderColor: "black",
    // borderStyle: "solid",
  },
  color: {
    color: "#040D12",
  },
  largeTitle: {
    fontSize: 28,
    borderRight: "2px solid #93B1A6",
    paddingRight: 10,
  },
  subSection: {
    marginBottom: 5,
  },
  date: {
    display: "flex",
    flexDirection: "row",

    gap: 10,
  },
});

const PdfFile = ({ appState }) => {
  const { personalInfo, education, experience, skills } = appState;
  const { name, email, phoneNumber, linkedIn, github, abstract } = personalInfo;
  const { degree } = education;

  return (
    <Document>
      <Page size="A4">
        <View style={styles.header}>
          <View>
            <Text style={[styles.title, styles.largeTitle]}>{name}</Text>
          </View>
          <View>
            <Text style={styles.headerInfo}>{phoneNumber} </Text>
            <Text style={styles.headerInfo}>{email} </Text>
            <Text style={styles.headerInfo}>{linkedIn}</Text>
            {github && <Text style={styles.headerInfo}>{github}</Text>}
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.section}>
            <View>
              <Text fixed style={styles.textHeader}>
                ABSTRACT
              </Text>
            </View>
            <View>
              <Text fixed style={styles.text}>
                {abstract}
              </Text>
            </View>
          </View>
          <View style={styles.section}>
            <View>
              <Text style={styles.textHeader}>WORK EXPERIENCE</Text>
            </View>
            {/* ---map--- */}

            {experience.experience.map((item, index) => {
              const { company, position, dateRange, description } = item;

              return (
                <View style={styles.subSection} key={index}>
                  {dateRange && (
                    <View style={styles.date}>
                      <Text style={styles.textSubHeader}>{position}</Text>
                      <Text style={styles.textSubHeader}>
                        ({dateRange[0]} to {dateRange[1]})
                      </Text>
                    </View>
                  )}
                  <View>
                    <Text style={styles.secText}>{company}</Text>
                  </View>
                  <View>
                    <Text style={styles.text}>{description}</Text>
                  </View>
                </View>
              );
            })}
          </View>

          <View style={styles.section}>
            <View>
              <Text style={[styles.textHeader, { marginTop: 0 }]}>
                EDUCATION
              </Text>
            </View>
            {degree.map((item, index) => {
              const { college, university, dateRange } = item;
              return (
                <View key={index}>
                  {dateRange && (
                    <View style={styles.date}>
                      <Text style={styles.textSubHeader}>{university}</Text>
                      <Text style={styles.textSubHeader}>
                        ({dateRange[0]} to {dateRange[1]})
                      </Text>
                    </View>
                  )}
                  <View>
                    <Text style={styles.secText}>{college} </Text>
                  </View>
                </View>
              );
            })}
          </View>

          <View style={styles.section}>
            <View>
              <Text style={styles.textHeader}>SKILLS</Text>
            </View>
            {skills.languages.map((item, index) => {
              const { name } = item;
              return (
                <View key={index}>
                  <Text style={styles.text}>{name}</Text>
                </View>
              );
            })}
          </View>

          {skills.achievements.length > 0 && (
            <View style={styles.section}>
              <View>
                <Text style={styles.textHeader}>CERTIFICATIONS</Text>
              </View>
              {skills.achievements.map((item, index) => {
                const { name } = item;
                return (
                  <View key={index}>
                    <Text style={styles.text}>{name}</Text>
                  </View>
                );
              })}
            </View>
          )}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default PdfFile;
