import { Button, Input, Tag } from "antd";
import { FieldArray, Formik, Form } from "formik";
import * as Yup from "yup";
import { useState } from "react";

const Form3 = ({ appState, setCurrent, setAppState }) => {
  const [langValue, setLangValue] = useState("");
  const [achValue, setAchValue] = useState("");

  const handlePrev = () => {
    setCurrent((prev) => prev - 1);
  };

  const validationSchema = Yup.object().shape({
    languages: Yup.array().of(
      Yup.object({
        id: Yup.number(),
        name: Yup.string(),
      })
    ),
    achievements: Yup.array().of(
      Yup.object({
        id: Yup.number(),
        name: Yup.string(),
      })
    ),
  });

  return (
    <Formik
      initialValues={
        appState.skills || {
          languages: [
            { id: 1, name: "Arabic" },
            { id: 2, name: "English" },
          ],
          achievements: [],
        }
      }
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setCurrent((val) => val + 1);

        setAppState((prev) => {
          if (prev.skills) {
            prev.skills = values;
            return prev;
          }

          return { ...prev, skills: values };
        });
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <FieldArray name="languages">
            {({ push }) => (
              <div className="my-4">
                <div className="row">
                  <label htmlFor="languages">Languages</label>
                  {/* Decided to make it this way because it's so much easier than using a Tag component as an input, however still used Tags below to show languages and achievements */}
                  <div className="col-10">
                    <Input
                      size="large"
                      type="text"
                      placeholder="Enter your languages"
                      value={langValue}
                      onChange={(e) => setLangValue(e.target.value)}
                    />
                  </div>
                  <div className="col-2">
                    <Button
                      disabled={!langValue}
                      onClick={() => {
                        const id = values.languages.length + 1;
                        if (
                          !values.languages.find(
                            (lang) => lang.name === langValue
                          )
                        ) {
                          push({ id, name: langValue });
                        }
                        setLangValue("");
                      }}
                    >
                      +
                    </Button>
                  </div>
                </div>
                {values.languages.map((language, index) => (
                  <Tag
                    key={language.id}
                    closable
                    onClose={() => {
                      const newLanguages = values.languages.filter(
                        (lang) => lang.id !== language.id
                      );
                      setFieldValue("languages", newLanguages);
                    }}
                  >
                    {language.name}
                  </Tag>
                ))}
              </div>
            )}
          </FieldArray>

          <FieldArray name="achievements">
            {({ push }) => (
              <div className="my-4">
                <div className="row">
                  <label htmlFor="achievements">Certifications</label>
                  <div className="col-10">
                    <Input
                      size="large"
                      type="text"
                      placeholder="Enter your achievements"
                      value={achValue}
                      onChange={(e) => setAchValue(e.target.value)}
                    />
                  </div>
                  <div className="col-2">
                    <Button
                      disabled={!achValue}
                      onClick={() => {
                        const id = values.achievements.length + 1;
                        if (
                          !values.achievements.find(
                            (ach) => ach.name === achValue
                          )
                        ) {
                          push({ id, name: achValue });
                        }
                        setAchValue("");
                      }}
                    >
                      +
                    </Button>
                  </div>
                </div>
                {values.achievements.map((achievement, index) => (
                  <Tag
                    key={achievement.id}
                    closable
                    onClose={() => {
                      const newAchievements = values.achievements.filter(
                        (ach) => ach.id !== achievement.id
                      );
                      setFieldValue("achievements", newAchievements);
                    }}
                  >
                    {achievement.name}
                  </Tag>
                ))}
              </div>
            )}
          </FieldArray>
          <Button size="large" type="primary" onClick={handlePrev}>
            Prev
          </Button>
          <Button
            className="mx-2 my-4"
            size="large"
            type="primary"
            htmlType="submit"
          >
            Next
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Form3;
