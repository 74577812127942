import { Form, Formik, Field } from "formik";
import { Button } from "antd";
import * as Yup from "yup";
import TextInput from "./TextInput";
// import ImageUpload from "./ImageUpload";
import TextArea from "antd/es/input/TextArea";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Form1 = ({ appState, setAppState, setCurrent }) => {
  return (
    <Formik
      initialValues={
        appState.personalInfo || {
          name: "",
          email: "",
          phoneNumber: "",
          linkedIn: "",
          github: "",
          country: "",
          city: "",
          street: "",
          imageUrl: "",
          abstract: "",
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required("name is required"),
        email: Yup.string().email().required("email is required"),
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
        phoneNumber: Yup.string()
          .matches(phoneRegExp, "Phone number is not valid")
          .required("Phone number is required"),
        abstract: Yup.string().required("abstract is required"),
        github: Yup.string(),
        linkedIn: Yup.string(),
      })}
      onSubmit={(values) => {
        setCurrent((pre) => pre + 1);
        setAppState((pre) => {
          if (pre.personalInfo) {
            pre.personalInfo = values;
            return pre;
          }
          return { ...pre, personalInfo: values };
        });
      }}
    >
      <Form>
        <div className="row g-sm-4  my-2">
          <div className="col-sm-4">
            <TextInput label="Name" name="name" placeholder="enter you name" />
          </div>
          <div className="col-sm-4 ">
            <TextInput
              label="Email"
              name="email"
              placeholder="enter you email"
            />
          </div>
          <div className="col-sm-4 ">
            <TextInput
              label="Phone Number"
              name="phoneNumber"
              placeholder="enter you phone number"
            />
          </div>
        </div>

        <div className="row g-sm-4 my-2">
          <div className="col-sm-6 ">
            <TextInput
              label="LinkedIn"
              name="linkedIn"
              placeholder="enter you linkedIn"
            />
          </div>
          <div className="col-sm-6">
            <TextInput
              label="Github"
              name="github"
              placeholder="enter you github"
            />
          </div>
        </div>

        <div className=" row g-sm-4 my-2">
          <div className="col-sm-4 ">
            <TextInput
              label="Country"
              name="country"
              placeholder="enter you country"
            />
          </div>
          <div className="col-sm-4 ">
            <TextInput label="City" name="city" placeholder="enter you city" />
          </div>
          <div className="col-sm-4  ">
            <TextInput
              label="Street"
              name="street"
              placeholder="enter you street"
            />
          </div>
        </div>

        <div className="my-4">
          <label htmlFor="abstract">Abstract</label>
          <Field name="abstract">
            {({ field, meta }) => {
              return (
                <div>
                  <TextArea
                    placeholder="Write a brief text about who you are and what are your goals"
                    {...field}
                  />
                  {meta.touched && meta.error && (
                    <div style={{ color: "red" }}>{meta.error}</div>
                  )}
                </div>
              );
            }}
          </Field>
        </div>

        {/* <div className=" row g-sm-2 my-2">
          <label>Personal Image</label>
          <ImageUpload name="imageUrl" />
        </div> */}

        <Button
          size="large"
          className="my-4  "
          type="primary"
          htmlType="submit"
        >
          Next
        </Button>
      </Form>
    </Formik>
  );
};

export default Form1;
