import { Button } from "antd";
import logo from "./../images/شغلات (1).png";

const Welcome = ({ setCurrent }) => {
  return (
    <div
      className="container-fluid d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "#004AAD", height: "100vh" }}
    >
      <div className="container ">
        <div className="row ">
          <div className="col d-flex justify-content-center">
            <img src={logo} style={{ width: 300 }} alt="shglat" />
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <Button
              type="primary"
              size="large"
              style={{ background: "#FF6500" }}
              onClick={() => setCurrent((pre) => pre + 1)}
            >
              اصنع السيرة الذاتية مجاناً
            </Button>
          </div>
        </div>
        <div
          className="row d-flex flex-column my-5 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <h5 style={{ textAlign: "center", color: "#fff", marginBottom: 20 }}>
            للحصول على وظيفة انضم الى قناة التلكرام الخاصة بشغلات العراق{" "}
          </h5>

          <a
            style={{
              color: "#FF6500",
              textAlign: "center",
              fontSize: 20,
            }}
            href="https://t.me/shaghlat_iq"
          >
            اضغط هنا
          </a>
        </div>
      </div>
    </div>
  );
};
export default Welcome;
